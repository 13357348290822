<template>
  <div ref="ecran" class="sections" v-if="ecran && actif" @click="afficherSlide">
    <Logo v-if="ecran.afficherLogo && slideCourant.afficherLogo"></Logo>
    <Chargement v-if="slides.length>1"></Chargement>
    <template v-for="slide in ecran.slides">
      <transition :name="ecran.transition">
        <template v-if="slideCourant.id == slide.id">
          <Video v-if="slide.type == 'video'" :slide="slide" />

          <Illustration v-if="slide.type == 'image'" :slide="slide" />

          <Lien v-if="slide.type == 'lien'" :slide="slide" />

          <Texte v-if="slide.type == 'texte'" :slide="slide" />
        </template>
      </transition>
    </template>
    <div class="remote" v-if="help">
      <img src="@/assets/remote.png" />
    </div>
    <div class="mute" v-if="muted">
      <img src="@/assets/mute.png" />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      help: false,
      actif: true,
      ecran: false,
      id: false,
      index: 0,
      slideCourant: false,
      st: false,
    };
  },
  mounted() {
    this.id = this.$route.params.id;
    this.demarrer(true);
    console.log("ok");
    window.addEventListener("keyup", this.clavier);
  },
  computed: {
    slides() {
      let out = [];
      this.ecran.slides.forEach((slide) => {
        if (
          (!this.$route.query.slide && slide.actif == 1) ||
          this.$route.query.slide == slide.id
        ) {
          out.push(slide);
        }
      });
      return out;
    },
  },
  methods: {
    clavier(e) {
      console.log(e.key);
      if (e.key == "ArrowRight") {
        this.suivant();
      }
      if (e.key == "h") {
        this.help = !this.help;
      }
      if (e.key == "m") {
        this.toggleMute();
      }
      if (e.key == "Escape") {
        if (!this.actif) {
          document.location.reload();
        } else {
          this.actif = false;
        }
      }
      if (e.key == "x") {
        this.$router.push("/ecrans");
      }
      if (e.key == "ArrowLeft") {
        this.precedent();
      }
      if (e.key == "ArrowUp") {
        this.premier();
      }
      if (e.key == "ArrowDown") {
        this.dernier();
      }
      e.preventDefault();
    },
    suivant() {
      this.afficherSlide();
    },
    precedent() {
      this.index -= 2;
      if (this.index == -1) {
        this.index = this.slides.length - 1;
      }
      this.afficherSlide();
    },
    dernier() {
      this.index = this.slides.length - 1;
      this.afficherSlide();
    },
    premier() {
      this.index = 0;
      this.afficherSlide();
    },
    afficherSlide() {
      if (this.st) {
        clearTimeout(this.st);
      }
      this.setSlide();
      this.$bus.$emit("commencer-chargement", this.slideCourant);
      if (this.slides.length > 1) {
        this.index++;
        if (this.index == this.slides.length) {
          this.index = 0;
          this.demarrer();
        }
        this.st = setTimeout(() => {
          this.afficherSlide();
        }, this.slideCourant.duree * 1000);
      }
    },
    setSlide() {
      let tmp = this.slides[this.index];
      this.slideCourant = tmp;
    },
    demarrer(init = false) {
      console.log("chargement de l'écran");
      axios.post("ecrans", { clause: { id: this.id } }).then((response) => {
        this.ecran = response.data["ecrans"][0];
        if (init) {
          setTimeout(this.afficherSlide, 1000);
        }
      });
    },
  },
};
</script>
<style lang="scss">
html {
  overflow: hidden !important;
}
.sections {
  position: relative;
  min-width: 100vw;
  min-height: 100vh;
}

section[data-type] {
  position: absolute;
  width: 100%;
  height: 100%;
}

.logo svg {
  fill: currentColor;
  max-width: 50%;
}
.slowFade-enter-active,
.slowFade-leave-active {
  transition: opacity 2s;
}
.slowFade-enter,
.slowFade-leave-to {
  opacity: 0;
}
.fastFade-enter-active,
.fastFade-leave-active {
  transition: opacity 0.5s;
}
.fastFade-enter,
.fastFade-leave-to {
  opacity: 0;
}
.horizontal-enter-active,
.horizontal-leave-active {
  transition: margin 0.5s;
}
.horizontal-enter {
  margin-left: 100vw;
}
.horizontal-leave-to {
  margin-left: -100vw;
}

.vertical-enter-active,
.vertical-leave-active {
  transition: margin 0.5s;
}
.vertical-enter {
  margin-top: 100vw;
}
.vertical-leave-to {
  margin-top: -100vw;
}

#logo {
  position: fixed;
  bottom: 3%;
  right: 3%;
  fill: white;
  z-index: 111;
  width: 10%;
  opacity: 0.5;
  filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.7));
}
.remote {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  z-index: 99999999999999999999;
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
}
.mute {
  z-index: 999999999999999999999;
  position: fixed;
  top: 1em;
  right: 1em;
  width:10vw;
  height: 10vw;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>